<template>
  <div class="cv">
    <div class="canvas-container">
      <div class="canvas">
        <div class="canvas-content">
          <h1>Tom Draper</h1>
          <h4>Software Developer</h4>
          <div class="download-icon-container">
            <button>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
              </svg>
            </button>
          </div>
          <div class="links">
            <div>Manchester, United Kingdom</div>
            <div class="divider">|</div>
            <a href="https://github.com/tom-draper" target="_blank">github.com/tom-draper</a>
            <div class="divider">|</div>
            <a href="https://www.linkedin.com/in/tomjdraper/" target="_blank">linkedin.com/tom-draper</a>
            <div class="divider">|</div>
            <a href="https://tomdraper.dev/" target="_blank">tomdraper.dev</a>
            <div class="divider">|</div>
            <div>tomjdraper1@gmail.com</div>
          </div>
          <p class="intro">
            I'm a Software Developer with a passion for building high-quality software that is both performant and
            user-friendly. I have experience working closely with stakeholders during application development,
            demonstrating highly effective communication skills, bridging the gap between a user's requirements and
            mental
            models with the appropriate implementation details. I enjoy tackling hard problems and always strive for
            simplicity and elegance in system
            design.
          </p>
          <h2>Work Experience</h2>
          <h3>Aston Berkeley Systems</h3>
          <h4>Software Developer<span class="divider">|</span>Feb 2023 – Present</h4>
          <ul>
            <li>Led the development of the team's next-generation flagship product, resulting in a new version with
              significantly enhanced features, maintainability and performance.</li>
            <li>Directly addressed critical customer feedback by drastically improving load-time performance by up to
              70%.</li>
            <li>Proactively engaged directly with clients and key-stakeholders throughout the application development
              lifecycle, ensuring prompt bug resolution, gathering feedback and refining requirements.</li>
            <li>Pioneered a first-of-a-kind data pipeline for seamless migration from Sage 50 Accounts to Microsoft
              Business Central.</li>
            <li>Leveraged Microsoft Power Automate to extend and tailor the functionality of Microsoft Dynamics 365 and
              SharePoint to better align with customer needs.</li>
            <li>Developed innovative cloud-based extensions using AL to expand the capabilities of Microsoft Business
              Central.</li>
            <li>Automated workflow processes by creating scripts with Python, TypeScript and PowerShell to improve
              workflow productivity, including resiliant bulk file-uploading to SharePoint, saving £3,500 in annual
              costs.</li>
          </ul>

          <h2>Personal Projects</h2>
          <h3>API Analytics</h3>
          <h4>Go<span class="divider">|</span>Svelte<span class="divider">|</span>TypeScript<span
              class="divider">|</span>PostgreSQL<span class="divider">|</span>Python<span
              class="divider">|</span>Rust<span class="divider">|</span>C#<span class="divider">|</span>JavaScript<span
              class="divider">|</span>Ruby</h4>
          <h4 class="github-link"><a href="https://github.com/tom-draper/api-analytics"
              target="_blank">github.com/tom-draper/api-analytics</a></h4>
          <p>A lightweight monitoring and analytics solution for a range of API web-frameworks, complete with a powerful
            interactive dashboard. This is a long-term and ambitious project that has gained over 160,000 downloads to
            date with very positive feedback from users. It has taught me valuable lessons surrounding the handling of
            high-volume traffic, construction of performant SQL queries, transmission of large data payloads, CI/CD and
            application security.</p>

          <h3>pldashboard</h3>
          <h4>Python<span class="divider">|</span>Svelte + SvelteKit<span class="divider">|</span>TypeScript<span
              class="divider">|</span>MongoDB<span class="divider">|</span>Plotly.js</h4>
          <h4 class="github-link"><a href="https://github.com/tom-draper/pldashboard"
              target="_blank">github.com/tom-draper/pldashboard</a></h4>
          <p>A web-based dashboard presenting the current performance of each Premier
            League football team with over 12,000 monthly visitors. This dashboard is packed with interactive
            visualisations, curated metrics and
            AI-powered predictions.</p>

          <h3>Array 3D Viz</h3>
          <h4>TypeScript<span class="divider">|</span>Node.js<span class="divider">|</span>Three.js</h4>
          <h4 class="github-link"><a href="https://github.com/tom-draper/array-3d-viz"
              target="_blank">github.com/tom-draper/array-3d-viz</a></h4>
          <p>A unique web-application and CLI tool for visualising arrays and matrices in 3D space. Initially developed
            as a personal debugging tool, it has since grown in popularity with over 19,000 monthly
            active users.</p>

          <h2>Education</h2>
          <h3>University of Bath</h3>
          <h4>Master of Computing (MComp), Computer Science<span class="divider">|</span>Sep 2018 – July 2022</h4>
          <p>Relevant modules:
          <ul>
            <li>Data Structures and Algorithms <span>(72%)</span></li>
            <li>Advanced Algorithms and Complexity <span>(77%)</span></li>
            <li>Parallel Computing <span>(71%)</span></li>
            <li>Functional Programming <span>(70%)</span></li>
            <li>Machine Learning 2 <span>(79%)</span></li>
            <li>Artificial Intelligence <span>(79%)</span></li>
            <li>Databases <span>(75%)</span></li>
          </ul>
          Consistently involved in group projects and gained experience collaborating using Git and agile scrum
          methodology for team-based software engineering projects, continuously adapting to new challenges to achieve
          targets.
          </p>

          <h2>Awards and Achievements</h2>
          <h3>The Netcraft Award</h3>
          <h4>July 2019</h4>
          <p>An award for outstanding student first-year performance. Attended an event hosting guest talks and
            led a team in a fast-paced competitive blockchain hacking challenge.</p>

          <h2>Skills</h2>
          <ul class="skills">
            <li>Python</li>
            <li>Go</li>
            <li>SQL</li>
            <li>JavaScript</li>
            <li>TypeScript</li>
            <li>HTML/CSS</li>
            <li>C#</li>
            <li>Visual Basic</li>
            <li>.NET Framework</li>
            <li>.NET</li>
            <li>Sage 50 Accounts Development</li>
            <li>Microsoft Dynamics 365</li>
            <li>Microsoft Dynamics 365 Business Central</li>
            <li>SharePoint</li>
            <li>AWS</li>
            <li>Azure</li>
            <li>Svelte</li>
            <li>Vue</li>
            <li>MongoDB</li>
            <li>Excel</li>
            <li>Access</li>
            <li>ExcelScript</li>
            <li>Power Automate</li>
            <li>Git</li>
            <li>Linux</li>
            <li>Vercel</li>
            <li>Docker</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CV',
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.cv {
  background: rgb(242, 247, 252);
  width: 100%;
  font-family: "IBM Plex Mono", monospace;
  font-weight: 400;
  min-height: 100vh;
  color: #323336;
  font-size: 13px;
  line-height: 1.5;
}

.canvas-container {
  padding: 5em;
}

.canvas {
  background: white;
  max-width: 1000px;
  margin: 0 auto;
}

.canvas-content {
  padding: 4.4em 9em;
  font-size: 14px;
  position: relative;
}

.download-icon-container {
  position: absolute;
  top: 4em;
  right: 6em;

  button {
    cursor: pointer;
    width: 3em;
    height: 3em;
    background: white;
    border: 1px solid rgb(218, 218, 218);
    padding: 0.6em;
    transition: 0.1s;

    svg {
      opacity: 0.3;
    }
  }

  button:hover {
    background: rgb(250, 250, 250);
  }
}

h1 {
  font-weight: 500;
  margin: 0 0 1em;
  text-transform: uppercase;
}

h2 {
  margin: 4em 0 2.5em;
  font-size: 12px;
  color: #C1C5C7;
  text-transform: uppercase;
  font-weight: 400;
}

h3 {
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  margin: 1.6em 0 0;
}

h4 {
  color: #323336;
  font-weight: 400;
  font-size: 1em;
  color: #707678;
  margin: 1em 0 0;
}

.links {
  color: #707678;
  line-height: 1.5;
  margin: 1em 0;

  div,
  a {
    display: inline;
    margin: 1em 0;
  }
}

a {
  text-decoration: none;
  color: #707678;
}

a:hover {
  opacity: 0.75;
  cursor: pointer;
}

.divider {
  padding: 0 0.6em;
  overflow-wrap: anywhere;
}

p {
  color: #323336;
  margin: 0.5em 0;
}

.intro {
  color: #707678;
}

.github-link {
  margin-top: 0.5em;
}

.skills {
  line-height: 2.2;
  display: block;
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 0.8em;
    display: inline;
    border: 1px solid #4F6AF6;
    color: #4F6AF6;
    padding: 0 1em;
    margin-right: 0.8em;
    display: inline-block;
    transition: 0.1s;
  }

  li:hover {
    color: white;
    background: #4F6AF6;
  }
}

@media screen and (max-width: 1100px) {
  .canvas-content {
    padding: 4.4em 6em;
  }
}

@media screen and (max-width: 900px) {
  .canvas-content {
    padding: 4.4em 4em;
  }
}

@media screen and (max-width: 800px) {
  .canvas-content {
    padding: 2em 3em;
  }

  .download-icon-container {
    top: 2em;
    right: 3em;
  }
}

@media screen and (max-width: 700px) {
  .canvas-container {
    padding: 0;
  }
}

@media screen and (max-width: 500px) {
  .canvas-content {
    font-size: 1em;
  }

  .canvas-content {
    padding: 2em 1.5em;
  }

  .download-icon-container {
    right: 1.5em;
  }

  ul {
    padding-inline-start: 1.5em;
  }
}
</style>