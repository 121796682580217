<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import Sketch from './components/sketches/Sketch.vue';
import { debounce } from 'lodash';
import MainView from './components/MainView.vue';
import Hero from './components/Hero/MyHero.vue';

export default {
  name: 'App',
};
</script>

<style>
:root {
  --txt: ##3a322c;
  --txt-med: #8f8f8f;
  --bg: #1d1e21;
  /* --bg: #1b1c20; */
  --bg-root: #1d1e21;
  /* --bg-root: #1b1c20; */
  /* --accent-1: #d7c5dd; */
  --accent-1: #d7bcdf;
  /* --accent-2: #f7e8d4; */
  --accent-2: #f3e1c8;
  /* --accent-3: #cae8e4; */
  --accent-3: #bee7e1;
  /* --accent-4: #d5e3c3; */
  --accent-4: rgb(209, 248, 209);
  /* --accent-5: #e3c3c3; */
  --accent-5: #c3cbe3;
  --accent-6: #e5bbbb;

  /* --accent-1: #d5fff9; */
  /* --accent-2: #cad7ff; */
  /* --accent-3: #e9ffcb; */
  /* --accent-4: #f3d4ff; */
  /* --accent-5: #ffe7ae; */

  /* --accent-3: rgb(204, 230, 255); */
  /* --accent-4: #d7c5dd; */
  /* --accent-1: rgb(204, 230, 255); */
  /* --accent-2: #d7c5dd; */
  /* --accent-3: #d5e3c3; */
  /* --accent-4: #f7e8d4; */
}

#app {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

@font-face {
  font-family: 'Geist Mono';
  src: url('fonts/GeistMonoVF.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('fonts/HelveticaNeue.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('fonts/HelveticaNeue-Bold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url('fonts/HelveticaNeue-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'HelveticaNeue';
  src: url('fonts/HelveticaNeue-Light.ttf') format('truetype');
  font-weight: 300;
}

body {
  background: var(--bg-root);
}
html {
  scroll-padding-top: 70px; /* height of sticky header */
  scroll-behavior: smooth !important;
}

.agile__dot {
  margin: 0 1px;
}
</style>
