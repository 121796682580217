<template>
  <div>
    <div class="main-view" :class="{ hidden: scrolledDown }">
      <div class="sider sidebar">
        <div class="holder">
          <div class="xx" role="navigation" aria-labelledby="main-nav-heading">
            <div class="title" id="main-nav-heading">Contents</div>
            <a class="listitem" href="#intro">Intro</a>
            <a class="listitem" href="#about-me">About Me</a>
            <a class="listitem" href="#skills">Skills</a>
            <a class="listitem" href="#projects">Projects</a>
            <a class="listitem" href="#blog">Blog</a>
            <a class="listitem" href="#contact">Contact</a>
            <br />
            <div class="contact">
              <a href="mailto:tomjdraper1@gmail.com">tomjdraper1@gmail.com</a>
              <a href="https://github.com/tom-draper/">github.com/tom-draper</a>
            </div>
          </div>
        </div>
      </div>
      <div class="main">
        <section id="intro">
          <div class="txt">
            <h1>
              Hi,
              <br />
              I'm Tom Draper.
              <br />
              Full-stack engineer.
            </h1>
            <br />
            <a href="#about-me">About me
              <Fa fa="arrow-down" />
            </a>
          </div>
        </section>
        <section id="about-me" aria-labelledby="intro-heading">
          <h2 id="intro-heading">About Me</h2>
          <span>
            I'm a software developer with a Master's in Computer Science from
            the the University of Bath. Python, Go and TypeScript are my tools
            of choice and my interests lie in automation, simulation, data
            analysis and data visualisation. Teaching technology is also a
            passion of mine, manifesting in a series of programming and maths
            courses as well as my software development blog. My aim is to build
            a more intelligent future!
          </span>
        </section>
        <section id="skills">
          <h2 id="skills-heading">Skills</h2>
          <div class="languages">
            <div class="language">
              <div class="name">Python</div>
              <div class="bar">
                <div class="progress" style="width: 90%"></div>
              </div>
            </div>
            <div class="language">
              <div class="name">TypeScript</div>
              <div class="bar">
                <div class="progress" style="width: 80%"></div>
              </div>
            </div>
            <div class="language">
              <div class="name">HTML/CSS</div>
              <div class="bar">
                <div class="progress" style="width: 80%"></div>
              </div>
            </div>
            <div class="language">
              <div class="name">Go</div>
              <div class="bar">
                <div class="progress" style="width: 75%"></div>
              </div>
            </div>
            <div class="language">
              <div class="name">Visual Basic</div>
              <div class="bar">
                <div class="progress" style="width: 75%"></div>
              </div>
            </div>
            <div class="language">
              <div class="name">C#</div>
              <div class="bar">
                <div class="progress" style="width: 55%"></div>
              </div>
            </div>
          </div>
          <div class="other-skills">
            <div class="skills-icons">
              <div class="skill">
                <img class="skills-icon" src="/images/C.png" alt="C" title="C" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/dotnet.png" alt=".NET" title=".NET" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/rust2.png" alt="Rust" title="Rust" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/java.png" alt="Java" title="Java" />
              </div>

              <div class="skill">
                <img class="skills-icon" src="/images/nodejs.png" alt="Node.js" title="Node.js" />
              </div>

              <div class="skill">
                <img class="skills-icon" src="/images/svelte.png" alt="Svelte" title="Svelte" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/vue.png" alt="Vue.js" title="Vue.js" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/Nuxt.png" alt="Nuxt.js" title="Nuxt.js" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/react.png" alt="React" title="React" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/astro.png" alt="Astro" title="Astro" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/gin.png" alt="Gin" title="Gin" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/flask.png" alt="Flask" title="Flask" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/fastapi.svg" alt="FastAPI" title="FastAPI" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/django.png" alt="Django" title="Django" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/numpy.png" alt="Numpy" title="Numpy" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/pandas.png" alt="Pandas" title="Pandas" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/sklearn.png" alt="Sci-kit learn" title="Sci-kit learn" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/matplotlib.png" alt="Matplotlib" title="Matplotlib" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/seaborn.png" alt="Seaborn" title="Seaborn" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/plotly.png" alt="Plotly" title="Plotly" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/pytest.png" alt="Pytest" title="Pytest" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/git.png" alt="Git" title="Git" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/mysql.png" alt="MySQL" title="MySQL" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/postgres.svg" alt="PostreSQL" title="PostgreSQL" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/mongodb.png" alt="MongoDB" title="MongoDB" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/sass.png" alt="SCSS" title="SCSS" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/boostrap.png" alt="Bootstrap" title="Bootstrap" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/tailwind.png" alt="Tailwind" title="Tailwind" />
              </div>

              <div class="skill">
                <img class="skills-icon" src="/images/haskell.png" alt="Haskell" title="Haskell" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/netlify.png" alt="Netlify" title="Netlify" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/vercel.svg" style="filter: invert(100%)" alt="Vercel"
                  title="Vercel" />
              </div>

              <div class="skill">
                <img class="skills-icon" src="/images/linode.png" alt="Linode" title="Linode" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/aws.png" alt="AWS" title="AWS" />
              </div>
              <div class="skill">
                <img class="skills-icon" src="/images/docker.png" alt="Docker" title="Docker" />
              </div>
              <!-- <div class="skill">
                <img class="skills-icon" src="/images/notion.png" alt="Notion" title="Notion" />
              </div> -->
              <div class="skill">
                <img class="skills-icon" src="/images/excel.png" alt="Excel" title="Excel" />
              </div>
              <!-- <div class="skill">
                <img class="skills-icon" src="/images/premierepro.png" alt="Premiere Pro" title="Premiere Pro" />
              </div> -->
              <div class="skill">
                <img class="skills-icon" src="/images/photoshop.png" alt="Photostop" title="Photostop" />
              </div>
            </div>
          </div>
        </section>
        <section id="projects" aria-labelledby="project-heading">
          <h2 id="project-heading">Projects</h2>
          <div class="cards">
            <div class="card-col">
              <MyCard :item="projects[0]" :txt="select" />
              <MyCard :item="projects[2]" :txt="select" />
              <MyCard :item="projects[4]" :txt="select" />
              <MyCard :item="projects[6]" :txt="select" />
              <MyCard :item="projects[8]" :txt="select" />
              <MyCard :item="projects[10]" :txt="select" />
              <MyCard :item="projects[12]" :txt="select" />
              <MyCard :item="projects[14]" :txt="select" />
              <MyCard :item="projects[16]" :txt="select" />
              <MyCard :item="projects[18]" :txt="select" />
            </div>
            <div class="card-col">
              <MyCard :item="projects[1]" :txt="select" />
              <MyCard :item="projects[3]" :txt="select" />
              <MyCard :item="projects[5]" :txt="select" />
              <MyCard :item="projects[7]" :txt="select" />
              <MyCard :item="projects[9]" :txt="select" />
              <MyCard :item="projects[11]" :txt="select" />
              <MyCard :item="projects[13]" :txt="select" />
              <MyCard :item="projects[15]" :txt="select" />
              <MyCard :item="projects[17]" :txt="select" />
            </div>
          </div>
        </section>
        <section id="blog" aria-labelledby="blog-heading">
          <h2 id="blog-heading">Blog</h2>
          <p>
            In the blistering Summer of 2022, I launched blog to write about any
            topics within technology and software development that interest me
            to satisfy a creative itch. This project established an outlet where
            I can collect my thoughts and explore new ideas and hopefully
            provide some value to others. A minor focus on software performance began
            to emerged, with a series of blog posts investigating the improvement
            in performance of software through alternative implementations,
            exploring the limitations and quirks of programming languages. This
            project also provided me with an opportunity to learn server-side
            rendering (SSR) using Nuxt3.
          </p>
          <a href="https://blog.tomdraper.dev/" target="_blank">
            <div class="blog-logo">
              FIRESIDE
            </div>
          </a>
        </section>
        <section id="contact" aria-labelledby="contact-heading">
          <h2 id="contact-heading">Contact</h2>
          <div class="email-text">tomjdraper1@gmail.com</div>
          <br />
          <div class="socials">
            <a href="https://github.com/tom-draper" class="social-link" target="_blank">
              <img class="social-img" src="/images/GitHub-Mark.png" alt="GitHub" title="GitHub" />
            </a>
            <a href="https://www.linkedin.com/in/tomjdraper/" class="social-link" target="_blank">
              <img class="social-img" src="/images/LinkedIn_logo_initials.png" alt="LinkedIn" title="LinkedIn" />
            </a>
            <a href="https://bsky.app/profile/tomdraper.bsky.social" class="social-link bluesky-link" target="_blank">
              <img class="social-img bluesky-img" src="/images/bluesky.png" alt="LinkedIn" title="LinkedIn" />
            </a>
            <!-- <a href="/cv" class="social-link" target="_blank">
              <div class="cv-icon">CV</div>
            </a> -->
          </div>
        </section>
      </div>
    </div>
    <PopOver :selected="selected" :closeWindow="closePopOver" />
  </div>
</template>

<script>
import { debounce } from 'lodash';
import MyCard from './globals/MyCard.vue';
import Fa from './globals/FaFa.vue';
import PopOver from './PopOver.vue';

export default {
  components: {
    MyCard,
    Fa,
    PopOver,
  },
  data: () => ({
    projects: [
      {
        id: 15,
        images: [
          '/images/api-analytics.png',
          '/images/dashboard.png',
          '/images/monitoring.png',
        ],
        title: 'API Analytics',
        tags: ['Python', 'Rust', 'Go', 'JavaScript', 'Svelte'],
        description:
          'A monitoring and analytics solution for API frameworks, complete with a dashboard.',
        longDescription:
          'A monitoring and analytics solution for API frameworks, complete with a dashboard.',
        demo: 'https://my-api-analytics.vercel.app',
        source: 'https://github.com/tom-draper/api-analytics',
      },
      {
        id: 1,
        images: [
          '/images/Dashboard-Arsenal1.png',
          '/images/Dashboard-Arsenal2.png',
          '/images/Dashboard-Everton1.png',
          '/images/Dashboard-Everton2.png',
          '/images/Dashboard-Manchester-City.png',
        ],
        title: 'Premier League Statistics Dashboard',
        tags: ['Svelte', 'FastAPI', 'MongoDB', 'Plotly', 'Pandas'],
        description:
          'A football statistics dashboard featuring metrics, predictions and visualisations.',
        longDescription:
          'A football dashboard featuring curated metrics, predictions and intuitive visualisations.',
        demo: 'http://pldashboard.com',
        source: 'https://github.com/tom-draper/pldashboard',
      },
      {
        id: 0,
        images: [
          '/images/sky.png',
          '/images/sky2.png',
          '/images/pink-sky.png',
          '/images/stars.png',
          '/images/clouds.png',
          '/images/moon.png',
        ],
        title: 'Digital Sky',
        tags: ['TypeScript', 'Canvas'],
        description: 'An image generator for artificial stylistic skies.',
        longDescription: 'An image generator for artificial stylistic skies.',
        demo: 'https://tom-draper.github.io/digital-sky/',
        source: 'https://github.com/tom-draper/digital-sky',
      },
      {
        id: 3,
        images: [
          '/images/Data.png',
          '/images/Data2.png',
          '/images/Data3.png',
          '/images/Data4.png',
          '/images/Data5.png',
          '/images/Data6.png',
        ],
        title: 'Array 3D Visualiser',
        tags: ['JavaScript', 'Node.js', 'Three.js'],
        description:
          'A fully interactive tool to visualise and explore arrays in 3D space.',
        longDescription:
          'A fully interactive tool to visualise and explore 1D, 2D and 3D arrays in 3D space.',
        demo: 'https://array-3d-viz.vercel.app/',
        source: 'https://github.com/tom-draper/3d-data-viz',
      },
      {
        id: 2,
        images: ['/images/city-builder.png', '/images/City-Builder2.png'],
        title: 'Pixel City Builder',
        tags: ['TypeScript'],
        description:
          'A 2D sandbox game that uses small tiles to build an animated city.',
        longDescription:
          'A 2D sandbox game that uses small tiles to build an animated city with limitless potential.',
        demo: 'https://tom-draper.github.io/city-builder/',
        source: 'https://github.com/tom-draper/city-builder',
      },

      {
        id: 4,
        images: [
          '/images/Example2.png',
          '/images/Example.png',
          '/images/Example3.png',
        ],
        title: 'Python Call Graph Visualiser',
        tags: ['Node.js', 'vis.js'],
        description:
          'An interactive visualisation tool to explore the structure of your Python programs.',
        longDescription:
          'An interactive visualisation tool to explore the structure of your Python programs.',
        demo: '',
        source: 'https://github.com/tom-draper/call-graph-viz',
      },
      {
        id: 700,
        images: ['/images/log-analyzer.png', '/images/log-analyzer.png'],
        title: 'Log Analyzer',
        tags: ['Go', 'Svelte', 'Plotly'],
        description: 'A tool to turn log files into a dashboard in seconds.',
        longDescription:
          'A tool to turn log files into a dashboard in seconds.',
        demo: '',
        source: 'https://github.com/tom-draper/log-analyzer',
      },
      {
        id: 5,
        images: [
          '/images/Connectivity-Checker.png',
          '/images/Connectivity-Checker1.png',
        ],
        title: 'Connectivity Checker',
        tags: ['Svelte', 'Go', 'Plotly', 'MongoDB'],
        description:
          'A full-stack app to track the responsiveness of my hosted websites.',
        longDescription:
          "A full-stack app to track the responsiveness of my hosted websites. Inspired by Better Uptime's status page.",
        demo: 'https://connectivity-checker.netlify.app/',
        source: 'https://github.com/tom-draper/connectivity-checker',
      },
      {
        id: 10,
        images: ['/images/blackjack.png', '/images/blackjack.png'],
        title: 'Blackjack',
        tags: ['Python', 'Pygame'],
        description: 'A game of Blackjack with a graphical user-interface.',
        longDescription: 'A game of Blackjack with a graphical user-interface.',
        demo: '',
        source: 'https://github.com/tom-draper/blackjack',
      },

      {
        id: 623,
        images: ['/images/nlmrs.png', '/images/nlmrs2.png'],
        title: 'NLMrs',
        tags: ['Rust', 'Python', 'Matplotlib'],
        description: 'A Rust crate for building Neutral Landscape Models.',
        longDescription: 'A Rust crate for building Neutral Landscape Models.',
        demo: '',
        source: 'https://github.com/tom-draper/nlmrs',
      },
      {
        id: 16,
        images: ['/images/Persona.png', '/images/persona-usage.png'],
        title: 'Persona',
        tags: ['Python', 'FastAPI'],
        description:
          'Character profile generation based on a given location using real-world demographic data.',
        longDescription:
          'A script and API for probabilistically generate realistic and representative character profiles based on a given location using real-world demographic data.',
        demo: '',
        source: 'https://github.com/tom-draper/persona',
      },
      {
        id: 11,
        images: [
          '/images/Home.png',
          '/images/Typing.png',
          '/images/Results.png',
          '/images/Settings.png',
        ],
        title: 'Typing Speed',
        tags: ['Go', 'Bubble Tea'],
        description: 'A CLI typing speed tester.',
        longDescription:
          'A configurable CLI typing speed test complete with statistics and graphs.',
        demo: '',
        source: 'https://github.com/tom-draper/typing-speed',
      },
      {
        id: 18,
        images: ['/images/aitour3.png', '/images/aitour5.png', '/images/aitour.png', '/images/aitour6.png'],
        title: 'AI Tour',
        tags: ["SvelteKit", 'TypeScript', "OpenAI"],
        description:
          'A game designed for exploring a city. Submit places you wish to go and guess AI-generated riddles for hints.',
        longDescription:
          'A game designed for exploring a city. Submit places you wish to go and guess AI-generated riddles for hints.',
        demo: 'https://aitour.vercel.app/',
        source: '',
      },

      {
        id: 99,
        images: [
          '/images/steel2.png',
          '/images/steel.png'
        ],
        title: 'Steel',
        tags: ['Svelte', 'TypeScript', 'Rust', 'Docker'],
        description: 'An offline, browser-based file navigator and text reader, with powerful built-in visualisations.',
        longDescription:
          'An offline, browser-based file navigator and text reader, with powerful built-in visualisations.',
        demo: '',
        source: 'https://github.com/tom-draper/steel',
      },
      {
        id: 100,
        images: ['/images/ad1.png', '/images/ad2.png'],
        title: 'Auto Desktop',
        tags: ['C#', 'WinForms'],
        description:
          'A user-friendly Windows desktop application for automatic keyboard and mouse entry.',
        longDescription:
          'A user-friendly Windows desktop application for automatic keyboard and mouse entry.',
        demo: '',
        source: 'https://github.com/tom-draper/auto-desktop',
      },

      {
        id: 263,
        images: ['/images/repo-stats.png', '/images/repo-stats.png'],
        title: 'Repo Stats',
        tags: ['Rust'],
        description:
          'A tool for generating a statistical overview of a local or remote git repo.',
        longDescription:
          'A tool for generating a statistical overview of a local or remote git repo.',
        demo: '',
        source: 'https://github.com/tom-draper/repo-stats',
      },
      // {
      //   id: 6,
      //   images: [
      //     '/images/Notion-Courses.png',
      //     '/images/Notion-Courses2.png',
      //     '/images/Notion-Courses3.png',
      //   ],
      //   title: 'Programming & Maths Courses',
      //   tags: ['Vue.js', 'MongoDB', 'Notion'],
      //   description: 'Notion-based programming and maths courses.',
      //   longDescription:
      //     'Self-designed and produced programming and maths courses built using the Notion.',
      //   demo: 'https://notion-courses.netlify.app/',
      //   source: '',
      // },
      // {
      //   id: 7,
      //   images: ['/images/colours.png', '/images/sky-palette.png'],
      //   title: 'Image Colour Palette',
      //   tags: ['Python', 'Pillow'],
      //   description:
      //     'A configurable script to extract the colour palette from images.',
      //   longDescription:
      //     'A configurable script to extract the colour palette from an image.',
      //   demo: '',
      //   source: 'https://github.com/tom-draper/color-palette',
      // },

      // {
      //   id: 13,
      //   images: ['/images/pomodoro1.png', '/images/pomodoro2.png'],
      //   title: 'Pomodoro Timer',
      //   tags: ['Go', 'Bubble Tea'],
      //   description: 'A CLI pomodoro timer for productivity.',
      //   longDescription:
      //     'A CLI pomodoro timer for productivity with progress display.',
      //   demo: '',
      //   source: 'https://github.com/tom-draper/pomodoro-timer',
      // },
      // {
      //   id: 19,
      //   images: ['/images/code-snippet.jpg', '/images/code-snippet-2.png'],
      //   title: 'Code Snippets',
      //   tags: ['Astro', 'Svelte', 'TypeScript'],
      //   description:
      //     'A web app for effortlessly building beautiful and highly customisable code snippet screenshots.',
      //   longDescription:
      //     'A web app for effortlessly building beautiful and highly customisable code snippet screenshots.',
      //   demo: 'https://tom-draper.github.io/code-snippets/',
      //   source: 'https://github.com/tom-draper/code-snippets',
      // },
      // {
      //   id: 8,
      //   images: [
      //     {
      //       '/images/colour-themes.png',
      //     },
      //     {
      //       '/images/colour-themes.png',
      //     },
      //   ],
      //   title: 'Colour Theme Website',
      //   tags: ['Svelte'],
      //   description:
      //     'A website to display popular and curated colour design themes.',
      //   longDescription:
      //     'A website to display popular and curated colour design themes.',
      //   demo: 'https://colour-themes.netlify.app/',
      //   source: 'https://github.com/tom-draper/color-themes',
      // },
      {
        id: 12,
        images: ['/images/clockin7.png', '/images/clockin7.png'],
        title: 'Clock In',
        tags: ['Go', 'MySQL'],
        description: 'A CLI tool for tracking your working periods.',
        longDescription: 'A CLI tool for tracking your working periods.',
        demo: '',
        source: 'https://github.com/tom-draper/clockin',
      },
      {
        id: 60,
        images: ['/images/securenote.png', '/images/securenote.png'],
        title: 'Secure Notes',
        tags: ['C#', 'Razor Pages', 'PostgreSQL', 'Docker'],
        description:
          'A privacy-first anonymous note shareing service, with notes stoed against a unique ID.',
        longDescription:
          'A privacy-first anonymous note shareing service, with notes stoed against a unique ID.',
        demo: '',
        source: 'https://github.com/tom-draper/secure-notes',
      },
      // {
      //   id: 9,
      //   images: ['/images/weather.png', '/images/weather-app.png'],
      //   title: 'Weather App Design Concept',
      //   tags: ['Vue.js'],
      //   description:
      //     'A minimalist design prototype that intuitively conveys temperature with colour.',
      //   longDescription:
      //     'A minimalist weather app design prototype that intuitively conveys temperature with colour.',
      //   demo: 'https://tom-draper.github.io/weather-app/',
      //   source: 'https://github.com/tom-draper/weather-app',
      // },
      // {
      //   id: 17,
      //   images: ['/images/wiki-crawl9.png', '/images/wiki-crawl9.png'],
      //   title: 'Wiki Crawl',
      //   tags: ['Python'],
      //   description:
      //     'A CLI game that involves finding a path through wiki pages from a start topic to a finish topic.',
      //   longDescription:
      //     'A CLI game that involves finding a path through wiki pages from a start topic to a finish topic.',
      //   demo: '',
      //   source: 'https://github.com/tom-draper/wiki-crawl',
      // },
      // {
      //   id: 14,
      //   images: ['/images/img-crop1.png', '/images/img-crop1.png'],
      //   title: 'Image Crop',
      //   tags: ['Rust'],
      //   description:
      //     'A tool to perform fast image crops from the command-line.',
      //   longDescription:
      //     'A tool to perform fast image crops from the command-line.',
      //   demo: '',
      //   source: 'https://github.com/tom-draper/img-crop',
      // },
      {
        id: 999,
        images: [
          '/images/darts2.png',
          '/images/darts1.png',
        ],
        title: 'Darts Tracker',
        tags: ['Svelte', 'TypeScript'],
        description: 'A useful web app for tracking your darts scores.',
        longDescription: 'A useful web app for tracking your darts scores.',
        demo: '',
        source: 'https://github.com/tom-draper/darts',
      },
      {
        id: 991,
        images: [
          '/images/darts2.png',
          '/images/darts1.png',
        ],
        title: 'Darts Tracker',
        tags: ['Svelte', 'TypeScript'],
        description: 'A useful web app for tracking your darts scores.',
        longDescription: 'A useful web app for tracking your darts scores.',
        demo: '',
        source: 'https://github.com/tom-draper/darts',
      }
    ],
    selected: null,
    keys: { 37: 1, 38: 1, 39: 1, 40: 1 },
    wheelEvent: null,
    wheelOpt: null,
  }),
  mounted() {
    window.onscroll = debounce(this.scrollcalc, 5);
    this.scrollcalc();
    let anchorlinks = document.querySelectorAll('a[href^="#"]');

    for (let item of anchorlinks) {
      item.addEventListener('click', (e) => {
        let hashval = item.getAttribute('href');
        let target = document.querySelector(hashval);
        target.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        history.pushState(null, null, hashval);
        e.preventDefault();
      });
    }

    this.setUpScrollDisable();
  },
  methods: {
    preventDefault(e) {
      e.preventDefault();
    },
    preventDefaultForScrollKeys(e) {
      if (this.keys[e.keyCode]) {
        this.preventDefault(e);
        return false;
      }
    },
    setUpScrollDisable() {
      let supportsPassive = false;
      try {
        window.addEventListener(
          'test',
          null,
          Object.defineProperty({}, 'passive', {
            // eslint-disable-next-line getter-return
            get: function () {
              supportsPassive = true;
            },
          })
        );
        // eslint-disable-next-line no-empty
      } catch (e) { }

      this.wheelOpt = supportsPassive ? { passive: false } : false;
      this.wheelEvent =
        'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    },
    disableScroll() {
      window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
      window.addEventListener(
        this.wheelEvent,
        this.preventDefault,
        this.wheelOpt
      ); // modern desktop
      window.addEventListener('touchmove', this.preventDefault, this.wheelOpt); // mobile
      window.addEventListener(
        'keydown',
        this.preventDefaultForScrollKeys,
        false
      );
    },
    enableScroll() {
      window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
      window.removeEventListener(
        this.wheelEvent,
        this.preventDefault,
        this.wheelOpt
      );
      window.removeEventListener(
        'touchmove',
        this.preventDefault,
        this.wheelOpt
      );
      window.removeEventListener(
        'keydown',
        this.preventDefaultForScrollKeys,
        false
      );
    },
    select(id) {
      this.selected = this.projects.find((x) => x.id === id);
      this.disableScroll();
    },
    closePopOver() {
      this.selected = null;
      this.enableScroll();
    },
    scrollcalc() {
      let mainNavLinks = document.querySelectorAll('.xx a');
      let fromTop = document.documentElement.scrollTop;
      let navbarHeight = 200;

      mainNavLinks.forEach((link) => {
        if (link.hash == '') return;

        let section = document.querySelector(link.hash);
        if (section == null) return;
        if (
          section.offsetTop <= fromTop + window.innerHeight &&
          section.offsetTop + section.offsetHeight > fromTop + navbarHeight
        ) {
          link.classList.add('current');
          let allCurrents = document.querySelectorAll('.current');
          let allFirsts = document.querySelector('.first');
          if (allFirsts != null) {
            allFirsts.classList.remove('first');
          }
          allCurrents[0].classList.add('first');
        } else {
          link.classList.remove('current');
        }
      });
    },
  },
  props: {
    scrolledDown: Boolean,
  },
};
</script>

<style lang="scss" scoped>
#intro-heading {
  color: var(--accent-2);
}

#skills-heading {
  color: var(--accent-3);
}

#project-heading {
  color: var(--accent-4);
}

#blog-heading {
  color: var(--accent-5);
}

#contact-heading {
  color: var(--accent-5);
}

#intro {
  // padding: 4.1rem 0 !important;
  padding-top: 5.5rem;

  .txt {
    color: #ffffff;

    a {
      display: none;
      text-decoration: none;
      color: white;
    }
  }
}

@media screen and (max-width: 735px) {
  .cards {
    display: block !important;
  }

  .card {
    width: 100% !important;
    height: 33rem !important;
  }

  #intro {
    .txt {
      a {
        display: hidden;
      }
    }
  }

  .hidden {
    #intro {
      .txt {
        a {
          display: block;
        }
      }
    }
  }

  .cont {
    grid-template-areas:
      'datum'
      'extra'
      'title'
      'ort'
      'txt' !important;
    grid-template-columns: 100% !important;
  }

  .txt h1 {
    font-size: 7vw !important;
  }
}

#contact {
  a {
    color: white;

    i {
      font-size: 0.9rem;
    }
  }

  a:hover {
    color: hsl(0deg 0% 63%);
  }

  .email-text {
    letter-spacing: 0.03em;
  }

  .socials {
    display: flex;
  }

  .bluesky-img {
    background: white;
    width: 38px !important;
  }

  .bluesky-link {
    height: 50px;
    width: 50px;
    background: white;
    border-radius: 6px;
    display: grid;
    place-items: center;
  }

  .social-link:hover {
    text-decoration: none;
  }
  .social-link {
    margin-right: 15px;

    .social-img {
      width: 50px;
      border-radius: 6px;
    }


    .cv-icon {
      background: rgb(240, 238, 233);
      background: rgb(238, 230, 210);
      align-content: center;
      text-align: center;
      text-decoration: none;
      color: BLACK;
      width: 50px;
      height: 50px;
      border-radius: 6px;
      font-weight: 600;
      font-size: 0.9em;
      text-decoration: none !important;
    }

    .cv-icon:hover {
      text-decoration: none !important;
      font-weight: 700;
    }

    a {
      text-decoration: none !important;
    }
  }
}

.bildungsweg,
.lebenslauf {
  h3 {
    font-size: 0.9rem;
  }

  cursor: default;
}

.cont {
  display: grid;
  grid-template-areas:
    'datum title'
    'extra ort'
    '. txt';
  grid-template-columns: 11rem 370px;
  gap: 0rem 1rem;
  margin-bottom: 1.3rem;

  span:nth-child(1) {
    grid-area: datum;
    font-weight: 600;
    font-size: 0.9rem;
    color: hsl(0deg 0% 63%);
    letter-spacing: -0.02rem;
  }

  span:nth-child(2) {
    grid-area: extra;
    font-size: 0.87rem;
    color: hsl(0deg 0% 63%);
    letter-spacing: -0.02rem;
  }

  span:nth-child(3) {
    grid-area: title;
  }

  span:nth-child(4) {
    grid-area: ort;
    font-size: 0.9rem;
    color: hsl(0deg 0% 63%);
  }

  span:nth-child(5) {
    grid-area: txt;
    font-size: 0.9rem;
    color: hsl(0deg 0% 63%);
  }
}

#skills {
  .language {
    display: flex;
    max-width: 750px;
    margin: 10px 0;
  }

  .name {
    width: 150px;
  }

  .bar {
    margin: 10px 15% auto 0;
    background: #2e2f34;
    height: 6px;
    width: 100%;
    position: relative;
    border-radius: 4px 3px 3px 4px;

    .progress {
      background: var(--accent-3);
      position: absolute;
      left: 0;
      height: 6px;
      width: 10%;
      border-radius: 3px 0 0 3px;
    }
  }

  .experience-using {
    margin-top: 3em;
    max-width: 650px;

    .experiences {
      display: flex;
      flex-flow: wrap;
      margin-left: 1.5rem;
      margin-top: 0.4rem;

      .experience {
        margin: 0 5px;
      }
    }
  }

  .other-skills {
    margin-top: 4rem;

    .other-skills-title {
      margin: 20px 0;
    }

    .skills-icons {
      margin-right: 14%;
      display: grid;
      grid-template-columns: repeat(12, 1fr);

      .skill {
        display: grid;
        place-items: center;
        position: relative;

        .skills-icon {
          margin: auto;
          max-width: 40px;
          max-height: 40px;
          margin: 8px 0;
          opacity: 0.75;
          filter: contrast(1.2) grayscale(1);
          transition: 0.1s;

          &:hover {
            opacity: 1;
            filter: none;
            position: absolute;
            transform: translate(0, -2px);
          }
        }
      }
    }
  }
}

#blog {
  a {
    text-decoration: none;
    color: black;
    display: flex;
    width: fit-content;

    .blog-logo {
      display: grid;
      place-items: center;
      height: fit-content;
      height: 50px;
      padding: 0 1.4em;
      background: black;
      border-radius: 0.5rem;
      font-size: 3em;
      font-size: 0.9em;
      font-weight: 400;
      color: rgb(255, 220, 169);
      font-family: 'Geist Mono';
      display: flex;
    }

    .code-logo {
      display: grid;
      place-items: center;
      height: fit-content;
      height: 50px;
      padding: 0 1.4em;
      background: black;
      border-radius: 0.5rem;
      font-size: 3em;
      font-size: 0.9em;
      font-weight: 300;
      color: #9bdbfe;
      font-family: 'Geist Mono';
      display: flex;

      .code-logo-prompt {
        color: #1ad69c;
        margin-left: 0.7em;
      }
    }
  }
}

.contact {
  // margin: 2rem 0rem;
  display: flex;
  flex-flow: column;
  gap: 0.7rem;
  width: 100%;
  transition: opacity 0.3s;

  a {
    text-decoration: none;
    color: var(--txt-med);
    padding: 0rem 1.5rem;
  }
}

.contact {
  a:hover {
    color: white;
  }
}

#intro {
  a {
    color: var(--accent-1);
    text-decoration: none;

    i {
      margin-left: 0.4rem;
    }
  }
}

section {
  padding: 6rem 0rem;

  &:empty {
    display: none;
  }

  span {
    display: block;
    max-width: 550px;
  }
}

.mainitem-active {
  border-radius: 6px;
  background: hsl(220 100% 71% / 0.11);
  color: hsl(220 100% 53% / 1) !important;
  margin-bottom: 0.3rem;
}

.title {
  font-size: 12px !important;
  font-weight: 700 !important;
  margin: 0.2em 0em 1rem 0rem;
  text-transform: capitalize;
  color: #b2b2b2;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  width: 100%;
  text-align: center;
}

.xx {
  display: flex;
  flex-flow: column;
  align-items: self-end;
  gap: 0.7rem;

  a {
    transition: min-width 0.3s, border 0.3s, padding 0.3s, box-shadow 0.3s,
      background 0.15s;
    width: fit-content;
    min-width: 210px;
    max-width: -webkit-fill-available;
  }
}

.listitem:nth-child(2).first {
  background: var(--accent-1);
}

.listitem:nth-child(3).first {
  background: var(--accent-2);
}

.listitem:nth-child(4).first {
  background: var(--accent-3);
}

.listitem:nth-child(5).first {
  background: var(--accent-4);
}

.listitem:nth-child(6).first {
  background: var(--accent-5);
}

.listitem:nth-child(7).first {
  background: var(--accent-6);
}

.listitem:hover {
  background-color: hsla(0, 0%, 80%, 0.15);
}

.listitem {
  text-decoration: none;
  display: block;
  padding: 0.75rem 1.8rem;
  margin-right: 1rem;
  color: white;
  font-size: 0.95em;
  cursor: pointer;
  transition: border 0.3s, padding 0.3s, box-shadow 0.3s, background 0.15s;
  border-left: 2px solid transparent;
  font-weight: 500;
  border-radius: 0.4rem;
}

.mainitem {
  padding: 0.5rem 0.5rem;
}

.first {
  color: black;
  color: black !important;
  padding-left: 2rem;
  font-weight: 600;
  transform-origin: left;
  animation: pop 0.25s ease-out;
}

@keyframes pop {
  35% {
    transform: scale(1.05);
  }

  75% {
    transform: scale(0.96);
  }

  100% {
    transform: scale(1);
  }
}
</style>

<style lang="scss" scoped>
.card-col:nth-child(2) {
  margin-top: 8em;
}

.cards {
  display: flex;
  gap: 1.7rem;
  width: fit-content;
}

.card-col {
  display: flex;
  flex-flow: column;
  gap: 1.7rem;
}

.sidebar,
.sideoptions {
  transition: width 0.3s;
}

@media screen and (max-width: 1300px) {
  .main-view {
    grid-template: 'siderbar main main';
  }

  .sideoptions {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .main-view {
    grid-template: 'main main main' !important;
    grid-template-columns: 1fr !important;
    width: auto !important;
  }

  .sidebar {
    display: none;
  }

  #skills {
    .language {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 900px) {
  #skills {
    .other-skills {
      .skills-icons {
        grid-template-columns: repeat(10, 1fr);
      }
    }
  }
}

@media screen and (max-width: 735px) {
  .card-col:nth-child(2) {
    margin-top: 1.7rem;
  }

  #skills {
    .other-skills {
      .skills-icons {
        grid-template-columns: repeat(9, 1fr);
      }
    }
  }
}

@media screen and (max-width: 600px) {
  #skills {
    .bar {
      margin-right: 0;
    }
  }

  #skills {
    .other-skills {
      .skills-icons {
        grid-template-columns: repeat(8, 1fr);
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .txt h1 {
    font-size: 8vw !important;
  }

  h2 {
    font-size: 1.2rem !important;
  }

  #skills {
    .other-skills {
      .skills-icons {
        grid-template-columns: repeat(5, 1fr);
        margin-right: 0;
      }
    }
  }

  // #blog {
  //   a {
  //     .code-logo {
  //       width: 100%;
  //       font-size: 2.6em;
  //     }
  //   }
  // }
}

@media screen and (max-width: 400px) {
  .cards {
    width: auto;
  }

  .cards {
    margin: 0 10px;
  }
}

.holder {
  scrollbar-color: #bebebe transparent;
  scrollbar-width: thin;
}

.holder:hover {
  scrollbar-color: #bebebe #f2f2f2;

  scrollbar-width: revert !important;
}

.holder::-webkit-scrollbar {
  width: 15px;
  padding: 10px;
}

.holder::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 5px solid transparent;
  background-clip: content-box;
}

.sider:hover .holder::-webkit-scrollbar {
  width: 15px;
}

.sider:hover .holder::-webkit-scrollbar-track {
  border-radius: 10px;
}

.sider:hover .holder::-webkit-scrollbar-thumb {
  border: 0;
  border-radius: 10px;
}

.sider:hover .holder::-webkit-scrollbar-thumb:hover {
  background: #818b99;
}

.sider {
  height: 100%;
  width: 310px;
}

.holder {
  padding: 1em 1em 0;
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  height: calc(100vh - 90px);
  overflow-y: auto;
}

.sidebar {
  justify-self: right;
}

.link .fas {
  font-size: 0.8rem;
}

.filters>.text {
  padding: 0.5em 0em;
  display: block;
}

.filters>.text>input {
  width: 125px;
  border: 0;
  background: 0;
  margin: 0rem 0.3rem;
  border-bottom: 1px solid gray;
}

.filters {
  margin: 2rem 0rem;
}

h2 {
  font-size: 1rem;
  color: var(--txt-med);
  margin-bottom: 2rem;
  cursor: default;
}

.main-view {
  display: grid;
  grid-template: 'siderbar main';
  grid-template-columns: 1fr minmax(600px, 770px);
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  z-index: 100;
}

div>.main {
  max-width: 1000px;
  margin: auto;
  padding: 2rem;
  box-sizing: border-box;
  line-height: 1.6;
  color: var(--txt);
  padding: 1.5rem 1.5rem 46vh 1.5rem;
  width: 100%;
  z-index: 100;
}

@media screen and (max-width: 600px) {
  div>.main {
    padding-bottom: 50px;
  }
}
</style>
<style scoped lang="scss">
.txt h1 {
  font-size: 3rem;
  margin: 0.2rem 0;
  line-height: 1.3;
  max-width: 600px;
}

.main-view {
  transition: background 0.3s;

  section {
    transition: opacity 0.3s;
  }
}

.main-view,
.main-view {

  .main .holder,
  .sider {
    transition: background 0.3s;
  }
}

.hidden {
  .xx {
    a {
      transition: min-width 0.3s;
      min-width: 0px;
      width: fit-content;
    }

    .title {
      opacity: 0;
    }

    .listitem {
      border: 0;
      color: black;
    }

    .listitem:nth-child(2) {
      background: var(--accent-1);
    }

    .listitem:nth-child(3) {
      background: var(--accent-2);
    }

    .listitem:nth-child(4) {
      background: var(--accent-3);
    }

    .listitem:nth-child(5) {
      background: var(--accent-4);
    }

    .listitem:nth-child(6) {
      background: var(--accent-5);
    }

    .listitem:nth-child(7) {
      background: var(--accent-6);
    }

    .listitem:hover {
      background: #fbfbfb;
    }
  }

  .contact,
  section {
    opacity: 0;
  }

  .main,
  .holder,
  .sider {
    background: transparent !important;
  }

  background: transparent !important;
  box-shadow: unset !important;
}

#intro {
  opacity: 100;
}
</style>
